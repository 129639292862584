// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Link } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

// components
import Page from 'src/components/Page';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ThgqpNutzungsbedingungen() {
  return (
    <Page title="Nutzungsbedingungen" sx={{ display: 'flex', flexGrow: 1, margin: 'auto' }}>
      <RootStyle>
        <Container
          sx={{
            '& ul': {
              paddingLeft: '50px',
            },
            mt: 15,
            mb: 10,
            position: 'relative',
          }}
        >
          <Box sx={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
            <PrintIcon
              onClick={() => {
                if (window) {
                  window.print();
                }
                return false;
              }}
            />
          </Box>
          {/* <FaqsCategory /> */}

          <Typography variant="h3" sx={{ mb: 1 }}>
            Nutzungsbedingungen
          </Typography>
          <Typography variant="h7">Stand Januar 2023</Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            1. Leistungsumfang, Geltungsbereich
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Picsure GmbH („<strong>Picsure“</strong>) betreibt die Plattform THG-Quotenportal („
            <strong>Portal“</strong>) unter der Domain „https://app.thg-quotenportal.de“ für die Vermarktung und den
            Verkauf von anrechenbaren Treibhausgasminderungsquoten von Elektrofahrzeugen („<strong>THG-Quote</strong>“).
            Ab dem 01.01.2022 können Anbieter fossiler Kraftstoffe ihrer Verpflichtung zur Minderung von
            Treibhausgasemissionen auch nachkommen, indem sie sich die eingesparten CO2-Emissionen der Halter von
            Elektrofahrzeugen anrechnen lassen. Auf der Grundlage dieses Konzepts bietet das Portal die Möglichkeit, die
            Fahrzeugscheine der eigenen E-Fahrzeuge hochzuladen, um über die Plattform einen Vermarktungsprozess für den
            Verkauf von anrechenbaren Treibhausgasminderungsquoten von Elektrofahrzeugen an ausgewählte
            THG-Quotenaufkäufer anzustoßen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Das Portal zum Verkauf der THG-Quote können ausschließlich Kunden („<strong>Nutzer“</strong>) nutzen, die
            für den THG-Quotenhandel berechtigt sind.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.3
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Über das Portal können Nutzer rund um die Uhr (mit Ausnahme von Zeiten der Wartung oder unvorhergesehenen
            Störungen):
          </Typography>

          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                für den Verkauf der THG-Quote erforderliche persönlichen Daten wie z.B. Adress- und Kontoinformationen
                eingeben
              </li>
              <li>
                Fahrzeugdaten und benötigte Fahrzeugscheine hochladen und die Daten zum Start des Vermarktungsprozesses
                der THG-Quote absenden
              </li>
              <li>den Status während des Vermarktungsprozesses einsehen</li>
              <li>die THG-Quote auch für Folgejahre immer wieder neu beantragen</li>
            </ul>
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Nutzer der Portals muss im Rahmen der E-Mail Bestätigung Halter oder ein berechtigter Vertreter („
            <strong>THG-Quotenberechtigte</strong>“) des angemeldeten Elektrofahrzeugs sowie Kunde mit einem eigenen
            Zugang zum VU Kundenbereich sein. Der THG-Quotenberechtigte muss darüber hinaus ein Verbraucher sein, der
            das 18. Lebensjahr vollendet und seinen Wohnsitz in Deutschland hat sowie über ein deutsches Bankkonto
            verfügt. Dieser kann auch eine juristische Person sein, die ihren Sitz in Deutschland hat.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Angemeldet werden kann jedes in Deutschland zugelassene Elektrofahrzeug (BEV), welches im Fahrzeugschein im
            Feld P3 bei der Kraftstoffart bzw. Energiequelle als „Elektro“ und im Feld 10 den Code „0004“ eingetragen
            hat („<strong>Elektrofahrzeug</strong>“). Die THG-Quote des jeweiligen Elektrofahrzeugs darf nur einmal im
            Kalenderjahr vom THG-Quotenberechtigten zum Verkauf angeboten werden und nicht bereits zuvor anderweitig zum
            Verkauf angeboten worden sein.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.6
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Zugriff auf das Portal ist durch festgelegte Sicherheitsmechanismen geschützt. Weitere Informationen
            gemäß dem Zweck von Ziffer 1.1. dieser Nutzungsbedingungen finden Sie auch in den Hinweisen zur{' '}
            <Link color={'#000'} underline="always" href="/thgqp/datenschutz" target="_blank" rel="noopener noreferrer">
              Datenschutzerklärung
            </Link>
            .
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.7
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Vertrag über den THG-Quotenhandel kommt mit Picsure zu Stande und wird in separaten Bedingungen (
            <Link color={'#000'} underline="always" href="/thgqp/agb" target="_blank" rel="noopener noreferrer">
              AGB
            </Link>
            ) geregelt.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.8
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Diese Nutzungsbedingungen gelten nur für die Nutzung des Portals im Sinne von Ziffer 1.1. dieser
            Nutzungsbedingungen.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            2. Vertragsschluss
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            2.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Ins Portal übermittelt der Nutzer zunächst die für den THQ-Quotenhandel erforderlichen Daten inklusive der
            Kopie des Fahrzeugscheins (Vorderseite).
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            2.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Nach erfolgter Eingabe aller Daten wird dem Nutzer eine Zusammenfassung der eingegebenen Daten dargestellt.
            Des Weiteren muss der Nutzer mit den Nutzungsbedingungen einverstanden sein, die{' '}
            <Link color={'#000'} underline="always" href="/thgqp/datenschutz" target="_blank" rel="noopener noreferrer">
              Datenschutzerklärung
            </Link>{' '}
            gelesen haben sowie dem Vertragsabschluss und den damit einhergehenden{' '}
            <Link color={'#000'} underline="always" href="/thgqp/agb" target="_blank" rel="noopener noreferrer">
              AGB
            </Link>{' '}
            von Picsure zustimmen.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            2.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Nach erfolgreicher Übermittlung der Daten an Picsure erhält der Nutzer eine Bestätigungs-E-Mail an die zuvor
            angegebene E-Mail-Adresse. Im Rahmen einer zeitlichen Vorgabe (üblicherweise 12 Stunden) muss der Nutzer aus
            der Bestätigungs-E-Mail heraus die E-Mail-Adresse bestätigen. Mit Bestätigung der E-Mail-Adresse wird ein
            rechtsverbindlicher Vermarktungsauftrag unter den zuvor festgelegten Bedingungen geschlossen und die
            Vermarktung gestartet.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            2.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Nachdem der Nutzer die Vermarktung der THG-Quote aktiv gestartet hat, erhält er eine weitere E-Mail, über
            die er jederzeit den Status seines Vermarktungsauftrags abrufen kann. Mit dem anschließenden Erhalt einer
            Status-E-Mail kommt der THG-Quotenvertrag zur Vermarktung der THG-Quote mit Picsure zustande.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            2.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Hinweis zum Erlöschen des Widerrufsrechts: das Widerrufsrecht erlischt vorzeitig, wenn die vertraglich
            vereinbarte Dienstleistung vor Ablauf der Widerrufsfrist vollständig erbracht wurde und mit der Ausführung
            des Vermarktungsprozesses begonnen wurde, nachdem der Nutzer seine ausdrückliche Zustimmung gegeben hat,
            indem er die Vermarktung der THG-Quote aktiv gestartet hat. Gleichzeitig wird mit dem Einverständnis zu den
            Nutzungsbedingungen auch das Einverständnis gegeben, dass das Widerrufsrecht bei vollständiger
            Vertragserfüllung vorzeitig erlischt.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            2.6
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Sobald der Nutzer die Vermarktung seiner THG-Quote aktiv gestartet hat, ist der Vermarktungsprozess nicht
            mehr zu stoppen oder zu löschen.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            2.7
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure ist berechtigt den Vermarktungsprozess durch Rücktritt vom Vertrag gemäß AGB (z.B. Lesbarkeit,
            Mehrfacheinreichung oder Missbrauchsverdacht) abzulehnen. Der Nutzer wird in diesen Fällen in einer
            separaten E-Mail über die Ablehnung informiert. Der Nutzer kann mit korrekten Daten einen neuen
            Vermarktungsprozess initiieren.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            2.8
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Nutzer wird in einer separaten E-Mail über den erfolgreiche Vermarktung der THG-Quote und der Auszahlung
            der zuvor vereinbarten THG-Quotenprämie informiert. Mit dieser Auszahlungs-E-Mail erhält der Kunde auch den
            entsprechenden Zahlungsbeleg inkl. aller nötigen Informationen, die für die Vermarktung gegolten haben.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            2.9
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Eine juristische Person darf nur von einer vertretungsberechtigten natürlichen Person vertreten werden, die
            nach dem Gesellschaftsvertrag, kraft Gesetzes oder aufgrund eines Rechtsgeschäfts hierzu berechtigt ist.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            3. Pflichten des Nutzers der Plattform
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            3.1
          </Typography>
          <Typography sx={{ mt: 1 }}>Es gelten folgende Hinweise zur sicheren Nutzung des Portals:</Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                Der Nutzer hat die Zugriffsberechtigungen zum Portal durch die E-Mail zur Verifizierung und Start der
                Vermarktung, der E-Mail zur Statusverfolgung sowie der E-Mail über die Prämienauszahlung in seinem
                persönlichen E-Mail Postfach geheim zu halten. Der Nutzer ist für alle unter den Zugangsdaten getätigten
                Handlungen und Erklärungen innerhalb der Plattform verantwortlich, es sei denn, diese Zugangsdaten
                wurden ohne Verschulden des Nutzers unrechtmäßig von Dritten erlangt.
              </li>
              <li>
                Ausgedruckte und gespeicherte Daten sowie den Zugang auf das Portal dürfen unbefugten Personen nicht
                zugänglich gemacht werden.
              </li>
              <li>
                Die Nutzer sind für den Schutz der Zugriffsberechtigungen voll verantwortlich. Sie tragen alle Schäden,
                die durch unsachgemäße und missbräuchliche Verwendung der Zugangsdaten und die Nichteinhaltung
                vorstehender Mitteilungsverpflichtungen entstehen.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            3.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure ist bei Vorliegen eines wichtigen Grundes berechtigt den Online-Zugang zu sperren und vom
            THG-Quotenvertrag zurückzutreten. Dies kommt insbesondere dann in Betracht, wenn der Verdacht des
            Missbrauchs besteht.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            3.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Nutzer hat bei der Nutzung des Portals für die Richtigkeit, Vollständigkeit und Aktualität der
            eingereichten Unterlagen und Angaben Sorge zu tragen. Der Nutzer darf keine verfälschten und manipulierten
            Daten und Informationen einreichen.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            3.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Nutzer darf die von ihm über das Portal übermittelten Quotendaten zuvor weder selbst noch über Dritte
            zur Zertifizierung beim Umweltbundesamt für das zugrunde gelegte Abrechnungsjahr eingereicht haben.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            4. Laufzeit
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Der Vertrag über die Nutzung der Plattform besteht nur für die Dauer des Vermarktungsprozesses von der
            Dateneinreichung, über die Beantragung beim Umweltbundesamt bis zum Verkauf der durch das Umweltbundesamt
            bescheinigten THG-Quote.
          </Typography>
          <Typography variant="h4" sx={{ mt: 5 }}>
            5. Haftung
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            5.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure haftet nicht für Pflichtverletzungen durch das Umweltbundesamt und/oder dem Quotenhändler.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            5.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure haftet nur für Schäden, die auf einer grob fahrlässigen Pflichtverletzung der Picsure oder auf einer
            vorsätzlichen oder grob fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen
            der Picsure beruhen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            5.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure haftet nur für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und im Übrigen
            für Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung der Picsure, ihrer
            gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Außerdem haftet Picsure für die fahrlässige
            Verletzung von wesentlichen Vertragspflichten, wobei die Haftung in diesem Fall auf den typischerweise
            vorhersehbaren Schaden beschränkt ist.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            6. Änderungen dieser Nutzungsbedingungen
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            6.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure kann diese Nutzungsbedingungen jederzeit ändern, soweit es dabei um hierin mitgeteilte
            Pflichtinformationen geht und durch solche Änderungen ihre und die Rechte und Pflichten des Nutzers
            unverändert bleiben.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            6.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Im Übrigen ist Picsure berechtigt, diese Nutzungsbedingungen mit Zustimmung des Nutzers zu ändern oder zu
            ergänzen, wenn und soweit durch unvorhersehbare Ereignisse, die nicht von Picsure veranlasst wurden und auf
            die Picsure auch keinen Einfluss haben (z.B. nachträgliche Veränderungen relevanter Gesetze oder
            Rechtsprechung). Wenn der Änderung anschließend nicht innerhalb der in der Ankündigungsmitteilung gesetzten
            Frist widersprochen wird, gilt Ihre Zustimmung zur Änderung als erteilt. Picsure weist den Kunden in der
            Ankündigungsmitteilung auf die Rechtsfolge eines unterlassenen Widerspruchs hin.
          </Typography>
          <Typography variant="h4" sx={{ mt: 5 }}>
            7. Sonstiges
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            7.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Nutzer kann nur mit rechtskräftig festgestellten oder unbestrittenen Gegenforderungen aufrechnen.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            7.2
          </Typography>
          <Typography sx={{ mt: 1 }}>Diese Nutzungsbedingungen unterliegen deutschem Recht. </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            7.3
          </Typography>
          <Typography sx={{ mt: 1 }}>Erfüllungsort ist der Sitz von Picsure in Hamburg.</Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            7.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Für Nutzer, der eine juristische Person ist, ist für sämtliche sich zwischen Picsure und diesem Unternehmer
            ergebenden Streitigkeiten aus den Nutzungsbedingungen der Gerichtsstand Hamburg.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            7.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Diese Nutzungsbedingungen können nur in Textform oder Schriftform geändert, ersetzt oder ergänzt werden.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            7.6
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Nichtigkeit oder Unwirksamkeit einzelner Bestimmungen dieser Nutzungsbedingungen berührt nicht die
            Gültigkeit der übrigen Teile. Anstelle der unwirksamen oder nicht durchsetzbaren Bestimmung treten, soweit
            vorhanden, die gesetzlichen Vorschriften.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.7
          </Typography>
          <Typography sx={{ mt: 1 }}>Vertragssprache ist Deutsch. </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.8
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit:
            <Link
              color={'#000'}
              underline="always"
              href="https://ec.europa.eu/consumers/odr"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr
            </Link>
            . Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus
            Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist. Picsure ist zur
            Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch
            bereit.{' '}
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.9
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Diese Vertragsbedingungen werden durch Picsure nicht gespeichert. Sie werden über die Internetseite nur
            während ihrer Gültigkeitsdauer zum Abruf bereitgehalten. Picsure empfiehlt den Nutzern, die
            Vertragsbedingungen durch Ausdruck oder Speicherung der Seite bzw. Datei auf ihrem Rechner selbst zu
            sichern.
          </Typography>
        </Container>
      </RootStyle>
    </Page>
  );
}
